<template>
  <div class="">
    <b-card class="">
      <h4><i class="fa fa-container-storage"></i> Cost Centers</h4>
      <hr>
      <div class="row">
        <div class="col-12 col-md-12">
          <h4>Create New Cost Center</h4>
        </div>
        <div class="col-12 col-md-12">
          <b-input-group>
            <b-form-input style="padding: 20px 10px;" name="center" v-model="centerName" type="text"
              class="form-control" placeholder="Name" />
            <b-input-group-append>
              <button type="button" @click="createCostCenter()" class="btn btn-success" name="button">
                Create <i class="fa fa-plus"></i>
              </button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h4>Select Existing Cost Center</h4>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-8">
              <multiselect @input="loadCostCenter()" v-model="costCenter" placeholder="Search Cost Center"
                tag-placeholder="Search Cost Center" track-by="cost_centerid" :options="ccData" :multiple="false"
                :close-on-select="true" :clear-on-select="false" :preserve-search="true" :taggable="false" label="name"
                :preselect-first="false">
              </multiselect>
            </div>
            <div class="col-4">
              <rotate-square2 style="mt-1 ml-2" v-show="loading"></rotate-square2>
              <button type="button" v-if="!lodash.isEmpty(costCenter)" v-show="!loading" @click="deleteCostCenter()"
                class="btn btn-danger" name="button">
                Delete <i class="fa fa-minus-circle"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-card>

    <b-card v-if="afeCostCenter.length > 0 && !lodash.isEmpty(costCenter)">
      <h4>AFE / PO Table</h4>
      <table class="table mt-2" v-show="!loading">
        <thead>
          <tr>
            <th>AFE Number</th>
            <th>Percentage</th>
            <th>Total Cost</th>
            <th>Total Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="afe in afeCostCenter">
            <td>{{ afe.afenum }}</td>
            <td>{{ afe.percent }}%</td>
            <td>${{ formatPrice(afe.totalCost) }}</td>
            <td>${{ formatPrice(afe.totalCost * (afe.percent / 100)) }}</td>
          </tr>
        </tbody>
      </table>
    </b-card>
    <b-card v-if="showBudget && !lodash.isEmpty(costCenter)">
      <h4>Budget Table</h4>
      <div class="row mt-2">
        <div class="col-md-12">
          <table class="table">
            <thead>
              <th>Date</th>
              <th>Budget</th>
              <th>Cost</th>
            </thead>
            <tbody>
              <tr v-for="line in budgets">
                <td style="vertical-align: middle">{{ line.start_date | moment('MMM YYYY') }}</td>
                <td>
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-input-group-text><i class="fa fa-dollar-sign"></i></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input @click="editBudgetLine(line.budgetid)" type="text" v-model="line.budget"
                      class="form-control" placeholder="Budget" />
                    <b-input-group-append>
                      <button :disabled="line.budgetid != budgetId" @click="saveBudget(line.budgetid, line.budget)"
                        type="button" name="button" class="btn btn-info white"><i class="fa fa-save"></i></button>
                    </b-input-group-append>
                  </b-input-group>
                </td>
                <td>${{ formatPrice(line.totalCost) }}</td>
              </tr>
              <tr v-if="budgets.length > 0" style="height: 30px">

              </tr>
              <tr style="background: #378e4333">
                <td style="vertical-align: middle"><strong>{{ date | moment("MMM YYYY") }}</strong></td>
                <td>
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-input-group-text><i class="fa fa-dollar-sign"></i></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input type="text" v-model="budget" class="form-control" placeholder="Budget" />
                  </b-input-group>
                </td>
                <td>
                  <button type="button" @click="addToBudgets()" class="btn btn-success" name="button">
                    Add Budget To Cost Center <i class="fa fa-plus"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import { ClientTable, Event } from 'vue-tables-2'
Vue.use(ClientTable)
import Multiselect from 'vue-multiselect'
import { RotateSquare2 } from 'vue-loading-spinner'
import Datepicker from 'vuejs-datepicker'


export default {
  name: "CostCenterCreateManager",
  components: {
    Multiselect,
    ClientTable,
    Datepicker,
    RotateSquare2,
  },
  data: function () {
    return {
      projectData: [],
      afeData: [],
      budgets: [],
      projectids: [],
      afes: [],
      date: "",
      costCenter: "",
      budget: "",
      chainData: [],
      centerName: "",
      percentage: [],
      centerAfes: [],
      theme: 'bootstrap4',
      template: 'default',
      bucket: [],
      center: [],
      profile: [],
      //Services Data Table
      centerColumns: ['name', 'delete'],
      centerOptions: {
        headings: {
          delete: 'Delete',
          name: 'Bucket Name',
        },
        sortable: ['cc1', 'cc2', 'cc3', 'afenum'],
        filterable: false,
        sortIcon: { base: 'fa', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort' },
        perPage: 10
      },
      ccData: [],
      afeCostCenter: [],
      loading: false,
      showBudget: false,
      budgetId: -1,
    }
  },
  methods: {
    async getAfeCost(afenum, start, end) {
      //cost_center
      return this.$http.get('cost_center/get/afe_cost/' + encodeURIComponent(afenum) + '/' + start + '/' + end)
        .then((response) => {
          return response
        })
        .catch((error) => {
          console.log(error)
        })
    },
    loadCostCenter() {
      //cost_center_budget/get/:cost_centerid
      this.showBudget = false;
      this.afeCostCenter = [];

      if (this.lodash.isEmpty(this.costCenter)) {
        return
      }

      this.loading = true;
      this.$http.get('/cost_center/get/all_costs/' + this.costCenter.cost_centerid)
        .then((response) => {


          if (response.data.result.budget_list.length > 0) {
            this.date = this.$moment(response.data.result.budget_list[response.data.result.budget_list.length - 1].start_date).add(1, 'month')
          }
          else {
            this.date = this.$moment();
          }

          var afe_list = response.data.result.afe_list;
          var afe_totals = response.data.result.afe_totals;
          var allAfeList = [];
          var budgets = response.data.result.budget_list;

          for (var i = 0; i < afe_list.length; i++) {
            allAfeList.push({
              afenum: afe_list[i].afenum,
              percent: afe_totals[i].percent,
              totalCost: afe_totals[i].afe_cost
            })
          }


          for (var i = 0; i < budgets.length; i++) {
            let budgetTotal = afe_totals.reduce(function (total, item) {
              return total + item.afe_cost
            }, 0);

            budgets[i].totalCost = budgetTotal;
          }

          this.budgets = budgets
          this.afeCostCenter = allAfeList
          this.loading = false;
          this.showBudget = true;
        }).catch(error => {
          this.$toasted.show('Error Loading Cost Center', { type: 'error', duration: '3000' })
          this.loading = false;
          this.showBudget = false;
        })
    },
    editBudgetLine(id) {
      if (this.budgetId >= 0 && this.budgetId != id) {
        this.$toasted.show('Please save previous budget.', { type: 'error', duration: '3000' })
        return
      }
      this.budgetId = id;
    },
    saveBudget(id, budget) {
      let data = JSON.stringify({
        cost_center_budgetid: id,
        budget: budget,
      })

      this.$http.post('/cost_center_budget/edit/', data)
        .then((response) => {
          this.budgetId = -1;
        })
    },
    // async loadCostCenter() {
    //   this.budgets = [];
    //   this.afeCostCenter = [];
    //   this.loading = true;
    //
    //   for (var i = 0; i < this.costCenter.budget.length; i++) {
    //     var start = this.$moment(this.costCenter.budget[i].date).startOf('month').format('YYYY-MM-DD')
    //     var end = this.$moment(this.costCenter.budget[i].date).endOf('month').format('YYYY-MM-DD')
    //
    //     var total = 0;
    //
    //     for (var x = 0; x < this.costCenter.afe_list.length; x++) {
    //       const cost = await this.getAfeCost(this.costCenter.afe_list[x].afenum, start, end);
    //       total += cost.data.result * (this.costCenter.afe_list[x].percentage / 100)
    //     }
    //
    //     this.budgets.push({
    //       "budget": this.costCenter.budget[i].budget,
    //       "date": this.costCenter.budget[i].date,
    //       "cost": total,
    //     })
    //   }
    //   for (var x = 0; x < this.costCenter.afe_list.length; x++) {
    //     const afeCost = await this.getAfeCost(this.costCenter.afe_list[x].afenum, this.$moment(this.costCenter.budget[0].date).startOf('month').format('YYYY-MM-DD'), "2025-01-01");
    //     var afe = afeCost.data.result * (this.costCenter.afe_list[x].percentage / 100)
    //
    //     this.afeCostCenter.push({
    //       "afenum": this.costCenter.afe_list[x].afenum,
    //       "percentage": this.costCenter.afe_list[x].percentage,
    //       "cost": afeCalculated,
    //     })
    //
    //     console.log(afeCost.data.result)
    //   }
    //   this.loading = false;
    //
    //   this.date = this.$moment(this.costCenter.budget[this.costCenter.budget.length -1].date).add(1, 'month')
    // },
    addToBudgets() {
      if (isNaN(this.budget)) {
        this.$toasted.show('Please enter numbers only.', { type: 'error', duration: '3000' })
        return
      }

      let data = JSON.stringify({
        cost_centerid: this.costCenter.cost_centerid,
        start_date: this.$moment(this.date).startOf('month').format('YYYY-MM-DD'),
        end_date: this.$moment(this.date).endOf('month').format('YYYY-MM-DD'),
        budget: this.budget,
        status: 1
      })

      this.$http.post('/cost_center_budget/create/', data)
        .then((response) => {
          this.budgets.push({
            "budget": this.budget,
            "start_date": this.date,
            "totalCost": 0,
            "budgetid": response.data.result.cost_center_budgetid
          })

          this.budget = 0;
          this.date = this.$moment(this.date).add(1, 'month')
        })
    },
    createCostCenter() {
      if (this.centerName == "") {
        this.$toasted.show('Name is empty', { type: 'error', duration: '3000' })
        return
      }

      let data = JSON.stringify({
        "name": this.centerName,
        "status": 1,
        "companyid": this.profile.companyid,
        "projectid": 0,
      })

      this.$http.post('/cost_center/create/', data)
        .then((response) => {
          console.log(response)
          this.$toasted.show('Successfully Created Center.', { type: 'success', duration: '3000' })
          //this.$router.push("/pm/buckets")
          // this.budgets = [];

          //this.loadCostCenters(this.profile.companyid)
          this.centerName = "";
          this.ccData.push(response.data.result)
          this.showBudget = true;
          this.budgets = [];
          this.afeCostCenter = [];

          this.costCenter = response.data.result
        })
        .catch((error) => {
          console.log(error)
        })
    },
    saveCostCenter() {
      var budget = [];

      for (var i = 0; i < this.budgets.length; i++) {
        budget.push({
          "date": this.budgets[i].date,
          "budget": this.budgets[i].budget,
        })
      }

      let data = JSON.stringify({
        "name": this.costCenter.name,
        "afe_list": this.costCenter.afe_list,
        "status": 1,
        "companyid": this.costCenter.companyid,
        "cost_centerid": this.costCenter.cost_centerid,
        "projectid": 0,
        "budget": budget,
      })

      this.$http.post('/cost_center/update/', data)
        .then((response) => {
          console.log(response)
          this.$toasted.show('Successfully Updated Center.', { type: 'success', duration: '3000' })
          this.loadCostCenters(this.profile.companyid)
          this.costCenter = {};
          this.budgets = [];
          this.date = this.$moment(new Date);
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteCostCenter() {
      let data = JSON.stringify({
        "name": this.costCenter.name,
        "status": 0,
        "companyid": this.profile.companyid,
        "cost_centerid": this.costCenter.cost_centerid,
        "projectid": 0,
      })

      this.$http.post('/cost_center/update/', data)
        .then((response) => {
          console.log(response)
          this.$toasted.show('Successfully Delete Center.', { type: 'success', duration: '3000' })
          this.loadCostCenters(this.profile.companyid)
          this.costCenter = {};
          this.centerName = "";
          this.budgets = [];
          this.date = this.$moment(new Date);
          this.afeCostCenter = [];
        })
        .catch((error) => {
          console.log(error)
        })
    },
    loadCostCenters(id) {
      this.$http.get('/cost_center/get/' + id)
        .then(response => {
          console.log(response)
          var temp = response.data.result.filter(x => x.cost_center.status == 1);

          var ccData = [];
          for (var i = 0; i < temp.length; i++) {
            ccData.push({
              name: temp[i].cost_center.name,
              cost_centerid: temp[i].cost_center.cost_centerid,
            })
          }
          this.ccData = ccData;
        })
        .catch(error => {
        })
    },
    get_profile() {
      this.$http.get('/manager/get/profile')
        .then(response => {
          console.log(response)
          this.profile = response.data;
          this.loadCostCenters(this.profile.companyid)
        })
        .catch(error => {
        })
    },
  },
  mounted() {
    this.get_profile();
    this.date = this.$moment(new Date);
  }
}
</script>

<style lang="scss">
.cc-date {
  padding: 7px;
}
</style>
